import { render, staticRenderFns } from "./Taio.vue?vue&type=template&id=51d4b559&scoped=true&"
import script from "./Taio.vue?vue&type=script&lang=js&"
export * from "./Taio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d4b559",
  null
  
)

export default component.exports